@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
  --black: rgb(0, 0, 0);
  --shadow: rgba(0,0,0,0.05);
  --gray1: rgb(116, 116, 116);
  --gray2: rgb(187, 187, 187);
  --gray3: rgb(248, 248, 248);
  --gray4: rgb(226, 226, 226);
  --gray5: rgb(238, 238, 238);
  --white: rgb(255, 255, 255);
  --color1: rgb(37, 157, 255);
  --color2: rgb(187, 39, 255);
  --color3: rgb(87, 179, 255);
  --color4: rgb(76, 185, 76);
  --color5: rgb(202, 48, 48);

  --transitionTime: 0.2s;
}

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "rubik";
}

h3 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 400;
}

p {
  font-weight: 400;
}

/* Deco */
.separator1 {
  margin: 10px auto;
  max-width: 50%;
  border: 2px solid var(--gray4);
  background-color: var(--gray1);
}

.separator {
  background-color: var(--black);
  margin: 10px 0 40px;
  max-width: 6%;
  height: 3px;
}

.gray-text {
  color: var(--gray1);
  transition: var(--transitionTime);
}

.gray-text2 {
  color: var(--gray2);
  transition: var(--transitionTime);
}

.gray-text3 {
  color: var(--gray3);
  transition: var(--transitionTime);
}

.gray-text4 {
  color: var(--gray4);
  transition: var(--transitionTime);
}

.color1-text {
  color: var(--color1);
  transition: var(--transitionTime);
}

.hover-link {
  transition: var(--transitionTime);
}

.hover-link:hover {
  color: var(--white);
}

.show-border {
  border: 2px solid pink; 
}

video {
  z-index: -1;
}

.add-shadow {
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
}

/* Navbar */
.navbar {
  background-color: var(--black);
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
}

.semi-transparent {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.85);
}

.navbar-container {
  max-width: 65%;
  margin: 0 auto;
}

.nav-links a {
  text-decoration: none;
  margin-left: 40px;
  transition: var(--transitionTime);
}

.nav-links a:hover {
  color: var(--white);
}

.google-accounts {
  margin-left: 40px;
}

.signout-button {
  background-color: var(--color1);
  color: var(--white);
  width: 110%;
  padding: 5px;
}

.signout-button:hover {
  background-color: var(--color3);
  border-color: var(--color3);
}

/* main content */
.main-content {
  max-width: 65%;
  margin: 0 auto;
  min-height: 100vh;
}

.sign-in-button {
  border: 2px solid var(--color1);
  padding: 10px;
  border-radius: 10px;
}

.sign-in-button:hover {
  background-color: var(--color1);
}

/* Footer */

.footer {
  box-shadow: 0 -2px 5px var(--shadow);
}

.footer-container {
  max-width: 65%;
  margin: 0 auto;
}

.footer-container > *{
  margin: 20px 0;
}

.footer-links {
  color: var(--gray1);
}

.footer-links > * {
  margin-bottom: 15px;
}

/* intro */
.video-container {
  height: 85vh;
  filter: brightness(0.2);
  position: relative;
}

.intro-container {
  top: 60%;
  left: 18%;
  transform: translateY(-100%);
  overflow: hidden;
  /* text-align: center;
  z-index: 5;
  top: 60%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-100%); */
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the video covers the entire container */
  position: absolute; /* Make the video fill the parent container */
  top: 0;
  left: 0;
  pointer-events: none;
}

.intro {
  border-bottom: 10px solid var(--black);
}

.ig-post {
  width: 350px;
}

.main-buttons {
  background-color: var(--color1);
  color: var(--white);
  border-radius: 10px;
  width: 150px;
  height: 50px;
  transition: var(--transitionTime);
}

.main-buttons:hover {
  background-color: var(--color3);
}


/* main gallery */

.grid-gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 6rem);
  grid-gap: 15px;
}

.grid-gallery__item {
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
}

.grid-gallery__img {
  z-index: -10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display:block;
  transition: 0.5s;
}

.grid-gallery__img:hover {
  transform: scale(1.05);
}

.grid-gallery__item--1 {
  grid-row: 1 / 4;
  grid-column: 1 / 3;
}

.grid-gallery__item--2 {
  grid-row: 1 / 4;
  grid-column: 3 / 5;
}

.grid-gallery__item--3 {
  grid-row: 1 / 5;
  grid-column: 5 / 9;
}

.grid-gallery__item--4 {
  grid-row: 7 / 9;
  grid-column: 1 / 3;
}

.grid-gallery__item--5 {
  grid-row: 7 / 9;
  grid-column: 3 / 5;
}

.grid-gallery__item--6 {
  grid-row: 4 / 7;
  grid-column: 1 / 5;
}

.grid-gallery__item--7 {
  grid-row: 5 / 9;
  grid-column: 5 / 7;
}

.grid-gallery__item--8 {
  grid-row: 5 / 9;
  grid-column: 7 / 9;
}


.redirect-button:hover {
  color: var(--black);
}

.redirect-button2:hover {
  color: var(--white);
}

/* Home service */

.service-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  position: relative;
}

.service-grid-item {
  border-radius: 1rem;
  overflow: hidden;
  flex: 1;
}

.service-grid-item-image {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  background-position: center;
  background-size: cover;
}

.service-grid-item-image-gradient {
  position: absolute;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(84, 84, 84, 0.349) 30%, rgba(194, 194, 194, 0) 100%);
  height: 100%;
  width: 100%;
}

.separator-service {
  width: 5%;
  height: 3px;
}

.separator-service.men {
  background-color: var(--color1);
}

.separator-service.women {
  background-color: var(--color2);
}

.service-grid-item-header {
  bottom: 0;
  color: var(--white);
}


.service-grid-item-description {
  background-color: black;
  color: var(--gray1);
}
.service-grid-item-description h4 {
  color: var(--gray2);
}

.service-grid-item-description ul {
  list-style-type: disc;
}

.service-grid-item-description ul li {
  margin-left: 1.5rem;
}

/* old grid */
.grid-services {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(1, 50vh);
  grid-gap: 4%;
}

.grid-services__item {
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.grid-services__item-1 {
  grid-row: 1/1;
  grid-column: 1 / 2;
}

.grid-services__item-2 {
  grid-row: 1/1;
  grid-column: 2 / 3;
}

.grid-services__item:hover .learn-more-services {
  color: var(--white);
}



/* Home Booking */
.grid-gallery-contact {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 15px;
}

.grid-gallery-contact__item--1 {
  grid-row: 1 / 9;
  grid-column: 1 / 5;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.grid-gradient1 {
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.911) 0%, rgba(0, 0, 0, 0.377) 30%, rgba(194, 194, 194, 0) 100%);
  height: 100%;
  width: 100%;
}


.grid-gallery-contact__item--2 {
  grid-row: 1 / 5;
  grid-column: 5 / 9;
  background: linear-gradient(0deg, var(--color1) 0%, rgb(152, 243, 255) 100%);
}

.grid-gallery-contact__item--3 {
  grid-row: 5 / 9;
  grid-column: 5 / 9;
  background: linear-gradient(0deg, var(--color2) 0%, rgb(231, 152, 255) 100%);
}

.grid-gallery-contact__item--2:hover .learn-more-contact {
  color: var(--white);
}

.grid-gallery-contact__item--3:hover .learn-more-contact {
  color: var(--white);
}

/* Services */
.table-container {
  width: 100%;
  border-radius: 15px;
  margin: 0 auto;
  overflow: hidden;
  transition: var(--transitionTime);
}

.service-table {
  width: 100%;
}

.service-table td, .service-table th{
  padding: 15px;
  height: 100px;
}


.service-table tr:nth-child(even) {
  background-color: var(--gray5);
}

/* booking */

.book-container {
  border-radius: 20px;
  flex-wrap: wrap;
}

.book-form {
  width: 500px;
  max-width: 500px;
  height: 600px; 
  text-align: center;
}
.book-form label {
  text-align: left;
  display: block;
}
.book-form h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 30px;
}
.book-form input, .book-form textarea, .book-form select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid var(--gray4);
  box-sizing: border-box;
  display: block;
}
.book-form button {
  background: var(--color1);
  width: 100%;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.book-form .disabled {
  background: var(--color3);
  width: 100%;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  cursor: no-drop;
}

.book-form .booking-found {
  background: var(--color4);
}

.your-bookings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 500px;
  max-width: 500px;
}

.booking-details {
  padding: 30px 10px;
}

.delete-booking {
  color: var(--color5);
}

.booking-table {
  margin: 0 auto;
}



/* gallery */
.gallery-image-preview {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: var(--transitionTime);
}

.preview-container {
  transition: var(--transitionTime);
  border-radius: 10px;
}

.preview-container:hover {
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
}

.preview-container:hover .gallery-image-preview {
  transform: scale(1.03);
}

.gallery-image-cont { 
  border-radius: 0.5rem;
  width: 350px;
  height: 400px;
  overflow: hidden;
  /* border: 2px solid var(--black); */
}

/* details */
.details-image-container {
  height: 70vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  background-size: contain;   
}

.comment-form textarea {
  border: 1px solid var(--gray4);
  border-radius: 5px;
  padding: 10px;
}

.comment-form button {
  background-color: var(--color1);
  padding: 7px 20px;
  border-radius: 10px;
  color: var(--white);
  transition: var(--transitionTime);
}

.comment-form button:hover {
  background-color: var(--color3);
}

.comment {
  border-bottom: 1px solid var(--gray4);
}

/* contact */
.contact-info {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.contact-image {
  background-position: center;
  background-size: cover;
  position: relative;
  max-width: 550px;
  width: 100%;
  height: 600px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
  border-radius: 1rem;
}

.contact-form {
  max-width: 45%;
  width: 500px;
  margin: 0 20px;
  border: 2px solid var(--gray4);
  border-radius: 10px;
  padding: 40px;
}

.contact-form input, .contact-form textarea {
  margin-bottom: 30px;
  border: none;
  border-bottom: 2px solid var(--gray4);
  resize: none;
}

.contact-form input:focus, .contact-form textarea:focus {
  outline: none;
}


/* privacy policy */
.privacypolicy p {
  color: var(--gray1);
}

.privacypolicy ul {
  list-style-type:disc;
  color: var(--gray1);
  padding: 10px 20px;
}
.privacypolicy ul li {
  margin: 10px 0;
}

.privacypolicy h2 {
  margin-top: 20px;
  font-size: xx-large;
}

.privacypolicy h3 {
  margin-top: 20px;
  font-size: x-large;
}
.privacypolicy h4 {
  margin-top: 10px;
  font-size: large;
}

/* responsiveness */
.nav-links {
  display: none; /* Default to hidden on small screens */
}

.nav-links-hamburger {
  display: none;
  background-color: var(--black);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.85);
  

}

.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  padding: 15px;
}


@media (min-width: 950px) {
  .nav-links {
      display: flex; /* Show the nav links on medium and larger screens */
  }
  .hamburger-menu {
    display: none; /* Hide the hamburger menu on medium and larger screens */
  }
  .show {
    display: none;
  }
}



@media (max-width: 1250px) {
  .main-content {
    max-width: 90%;
  }
  .navbar-container {
    max-width: 90%;
  }
  .footer-container {
    max-width: 90%;
  }
  .intro-container {
    left: 5%;
  }
}

@media (max-width: 900px) {
  .service-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 700px) {
  .grid-gallery {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(8, 40vh);
  }
  .grid-gallery__item--1 {
    grid-row: 1 / 1;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--2 {
    grid-row: 2 / 2;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--3 {
    grid-row: 3 / 3;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--4 {
    grid-row: 4 / 4;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--5 {
    grid-row: 5 / 5;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--6 {
    grid-row: 6 / 6;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--7 {
    grid-row: 7 / 7;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--8 {
    grid-row: 8 / 8;
    grid-column: 1 / 2;
  }
  .grid-gallery__item--9 {
    grid-row: 9 / 9;
    grid-column: 1 / 2;
  }

  .grid-gallery-contact {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(4, 40vh);
  }

  .grid-gallery-contact__item--1  {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }
  .grid-gallery-contact__item--2  {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }
  .grid-gallery-contact__item--3 {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

  .grid-services {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(2, 40vh);
  }

  .grid-services__item-1 {
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }

  .grid-services__item-2 {
    grid-row: 2 / 3;
    grid-column: 1 / 1;
  }

}

@media (max-width: 950px) {
  .contact-form {
    width: 100%;
    max-width: 90%;
    margin-top: 20px;
  }
  .contact-info {
    grid-template-columns: repeat(1, 1fr);
  }
  .contact-image {
    max-width: 90%;
  }

  

  .gallery-image-cont { 
    width: 80vw;
    height: auto;
    aspect-ratio: 0.8/1;
  }
  .gallery-post-container {
    justify-content: center;
  }
  
  
}